.productList{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    background-color: #181818;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.productListTitleProduct{
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom:30px ;
}
.productListTitleProduct a{
    font-size: 25px !important;
    color: rgb(248, 221, 147);
    font-weight: 600;
    text-decoration: none;
    
}
.productListImgs{
    width: 100%;
    display: flex;
    justify-content: center;
}
.productListImgTele{
    width: 70%;
}
.productListImgTele img{
    width: 100%;
    object-fit: contain;
    border-radius: 6px;
}
.productListImgMobil{
    width: 30%;
    display: flex;
    justify-content: right;
    display: none;
}
.productListImgMobil img{
    width: 80%;
    object-fit: contain;
    border-radius: 6px;
}
@media  screen and (max-width:1200px){
    .productListImgMobil img{
        margin-left: 10px;
    }
}
@media  screen and (max-width:1000px){
    .productListImgTele{
        width: 100%;
    }
}
@media  screen and (max-width:700px){
    .productList{
        padding-block: 20px;
        padding-inline: 10px;
    }

    .productListTitleProduct a{
        font-size: 22px !important;
        
    }
    .productListImgs{
      flex-direction: column;
    }
    .productListImgTele{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .productListImgMobil{
        width: 100%;
        display: flex;
        justify-content: center;

    }
    .productListImgMobil a{
        width: 100%;
        display: flex;
        justify-content: center;

    }
    .productListImgMobil img{
        width: 40%;
        margin-left: 0px;
    }
}