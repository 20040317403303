.nosotrosCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #181818;
}

.nosotrosCardContainerImg{
    width: 100%;
    display: flex;
    justify-content: center;
}
.nosotrosCardImg1{
    width: 450px;
    object-fit: contain;
    margin-bottom: 20px;
}
.nosotrosCardImg2{
    width: 650px;
    object-fit: contain;
}
.nosotrosCardText{
    padding-inline:200px ;
    margin-top: 5px;
}
.nosotrosCardText p{
    color: rgb(206, 206, 206);
    font-size: 23px ;
    font-weight: 400;
    text-align: center;
}
@media  screen and (max-width:1200px){
    .nosotrosCardText{
        padding-inline:100px ;
    }
    
}
@media  screen and (max-width:900px){
    .nosotrosCardText{
        padding-inline:10px ;
    }
    .nosotrosCardText p{
        font-size: 22px ;
    }
    
}

@media  screen and (max-width:700px){
    .nosotrosCardImg1{
        width: 450px;
    }
    .nosotrosCardImg2{
        width: 500px;
    }

    
}

@media  screen and (max-width:500px){
    .nosotrosCardImg1{
        width: 350px;
    }
    .nosotrosCardImg2{
        width: 350px;
    }

    
}
@media  screen and (max-width:400px){
    .nosotrosCardImg1{
        width: 100%;
    }
    .nosotrosCardImg2{
        width: 100%;
    }
    .nosotrosCardText p{
        font-size: 20px ;
    }
    
}