.chat-bloque-2{
    width: 100%;
    display: flex;
    justify-content: center;
}
.chat-bloque-2-1{
    width: 100%;
    display: flex;
    justify-content: center;
    /*background-color: aqua;*/
}
.contendor-chat{
    
}
.chat-tipo-vacio{
    height: 80px;
}
.chat-tipo{
    max-width: 700px;
    display: flex;
    height: auto;
    
         
}
.linea-circulo-chat{
    width: auto;
    display: flex;
    background-color: #67D6ED;
}

.linea-chat{
    width: 2px;
    height: auto;
    /*background-color:rgba(18, 57, 165, 1);    */
    background-color: #67D6ED;
}
.chat-icono{
    position: absolute;
    width: 50px;
    height: 50px;
    /*border-radius: 50%;
    background-color:rgba(18, 57, 165, 1);*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -22px;
}
.img-icono{
    width: 100%;
}
.chat-icono p{
    font-size: 20px !important;
    color: rgb(248, 221, 147);
    margin-top: 10px;
}
.chat-tipo-titulo-text{
    margin-left: 30px;
    z-index: 10;
}
.chat-tipo-titulo{
    display: flex;
    align-items: center;
    height: 55px;   
   
}
.chat-tipo-titulo p{
    font-size: 21px !important;
    font-weight: 600;
    color: #67D6ED  ;
    
}
.chat-text{
    font-size: 18px !important;
    font-weight: 400;
    color: rgb(218, 218, 218);  
    background-color: #181818;
    padding: 10px;
    border-radius: 10px;
}
@media  screen and (max-width:1220px){
    .chat-tipo{
              
    } 

 
}
@media  screen and (max-width:550px){

    .chat-icono{
        position: absolute;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -20px;
    }
    .chat-tipo{
        width: 90%;
        display: flex;
        margin-left: 25px;
           
    }
    .linea-circulo-chat{
        width: auto;
        display: flex;   
    }
    .chat-tipo-titulo-text{
        margin-left: 24px;
    }
    .chat-tipo-titulo p{
        font-size: 21px !important;
        color: #000;   
    }
 
}
@media screen and (max-width:400px) {
    .imagen-bloque-2-1-img{
        width: 200px; 
    }
}
@media  screen and (max-width:330px){

    .chat-tipo-titulo p{
        font-size: 18px !important;
        color: #000; 
    }
}