.nav{
    position: fixed;
    width: 100%;
    z-index: 2;
    height: 50px;
    padding: 0px;
    border-bottom: 1px solid #67D6ED; 
    z-index: 100;
}
.botonMenu{
   display: none;
   
}

.logo{
    height: 20px;
    width: 30px;
    margin-right: 5px;
    
}


.menu >li:hover{
    /*border-bottom: 1px solid white;*/
}

@media screen and (max-width: 991px) {
    .botonMenu{
        display: block;
        background-color: #3C3C3C;
        border:1px solid  #3C3C3C; 
        padding: 5px 10px;
        border-radius: 5px;
        
    }
    .botonMenu:hover{
        border-color: rgba(29, 127, 240, 0.991);
    }
    .botonMenu span{
        color: rgb(92, 92, 93) !important;
    }
    .botonCerrar{
        box-shadow: inset 0 0 0 1000px rgba(52, 51, 51, 0.372);
    }

    .botonCerrar:focus{
        outline: none !important;
    }
    .botonCerrar:hover{
        border-color: red;
    }
    .menuLateral{
        width: 60%;
    }
    .menuLateral > .offcanvas-body{
        background-color: rgba(14, 13, 13, 0.999);
        padding-left: 0px;
    }
    .menuLateral > .offcanvas-header{
        background-color: rgba(14, 13, 13, 0.999);
    }
    .menuLateral > .offcanvas-header > .botonCerrar{
        background-color: rgb(111, 112, 112);
    }
    .menuLateral > .offcanvas-body > ul > li{
        list-style: none;
        width: 100%;
        margin-top: 10px ;
        margin-left: -30px !important;
    }
    .menuLateral > .offcanvas-body > ul >li:hover{
        background-color: rgb(98, 98, 97);
        border-radius: 5px;
    }
    .menuLateral > .offcanvas-body > ul > li > .nav-link{
        color: rgb(184, 187, 187);
        font-size: 20px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        margin-left: -10px !important;
    }
    .menuLateral > .offcanvas-body > ul > li > .nav-link > span{
        margin-right: 5px;
        font-size: 25px;
    }
}

@media screen and (max-width: 344px){
    .menuLateral{
        width: 70%;
    }
}