/********Bloque 2 de servicios **********************/

.servicios-bloque-2{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.servicios-bloque-2-1{
    width: 100%;
    display: flex;
    justify-content: center;
    /*background-color: aqua;*/
}
.contendor-servicios{
    
}
.servicio-tipo-vacio{
    height: 80px;
}
.servicio-tipo{
    max-width: 700px;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 60px;
    background-color: #181818;
    border-radius: 10px;
        
}

.servicio-icono{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.servicio-tipo-titulo{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-block: 20px;
   
}

.servicio-icono p{
    font-size: 25px !important;
    color: rgb(248, 221, 147);
    font-weight: 600;
}
.servicio-icono-resp{
    width: 400px;
    height: auto;
}
.img-icono{
    width: 100%;
    object-fit: contain;
}

.servicio-tipo-titulo-text{
    z-index: 10;
    display: flex;
    flex-direction: column;
}

.servicio-text{
    font-size: 19px !important;
    font-weight: 550;
    color: rgb(218, 218, 218);  
    background-color: #181818;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    
}

@media  screen and (max-width:900px){
    .servicio-text{
        text-align: center;
    }
}

@media  screen and (max-width:750px){
    .servicio-tipo{
        width: 100%;
        padding: 5px;
            
    }

    .servicio-icono-resp{
        width: 270px;
        height: auto;
    }


}
@media  screen and (max-width:550px){

    .servicio-icono p{
        font-size: 21px !important;
    }
    .servicio-icono-resp{
        width: 250px;
        height: auto;
    }

}
@media  screen and (max-width:250px){
    .servicio-icono-resp{
        width: 150px;
        height: auto;
    }
}

/********FIN DEL Bloque 2 de servicios **********************/