.banner-contenedor {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* box-shadow: inset 0 0 0 1000px rgba(11, 11, 11, 0.706); */
    /* background-color: #242526; */
    background-repeat: no-repeat;
    background-size: 100%;
    object-fit: contain;
    color: white;
    background-color: rgba(103, 214, 237, 0.1);
    /*background-image: url("../../media/fondo.jpg");*/
}

.bannerImagenFondo {
    z-index: -1;
    object-fit: cover;
    background-color: black;
    width: 100%;
    height: 100vh;
    position: fixed;

}


.filaImagenTexto {
    display: flex;
    padding-top: 20px;
    flex-direction: column;
}

.imagen {
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagen>img {}

.imagenMumbe {
    border-radius: 5px;
    height: 70vh;
    /* box-shadow: rgba(133, 133, 133, 0.47) -2px 3px 20px; */
    width: 420px;
    /* border-radius: 50%; */
    object-fit: contain;
}

.botonContactoContainer {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100px;
}

.botonContacto {
    background-color: #67D6ED;
    border-color: #67D6ED;
    color: #000000;
    font-weight: 700;
    position: absolute;
    width: 300px;
}



/* ----------------parte de resposive---------------- */


@media (max-width: 1000px) {

    .imagenMumbe{
        width: 400px;
    }

}

@media (max-width: 600px) {

    .imagenMumbe{
        width: 360px;
    }

}

@media (max-width: 450px) {

    .imagenMumbe{
        width: 340px;
    }

    .botonContacto {
        width: 200px;
    }

}

@media (max-width: 400px) {

    .imagenMumbe{
        width: 320px;
    }

}

@media (max-width: 350px) {

    .imagenMumbe{
        width: 300px;
    }

}

@media (max-width: 300px) {

    .imagenMumbe{
        width: 250px;
    }

    .botonContacto {
        width: 150px;
    }

}

@media (max-width: 250px) {

    .imagenMumbe{
        width: 200px;
    }

}

@media (max-width: 200px) {

    .imagenMumbe{
        width: 150px;
    }

}